import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Shared"

const PageNotFound = () => (
  <Layout>
    <header className="wrapper-width wrapper-height header">
      <section className="header-left">
        <h1 className="heading-extra-large">Page Not Found</h1>
        <p>
          Sorry, the page you're looking for is no longer available. This may be
          because it has been deleted or moved. Contact us if you think this has
          been done by mistake.
        </p>
        <div className="header-buttons">
          <Link to="/" className="button-primary">
            Back to Homepage
          </Link>
          <Link to="/contact-us" className="button-secondary">
            Contact us
          </Link>
        </div>
      </section>
    </header>
  </Layout>
)

export default PageNotFound
